function prodotti() {
    return {
        lenti: [],
        soluzioni: [],
        loading: true,
        mostraModale: false,
        urlLenti: "/api/lenses?",
        urlSoluzioni: "/api/solutions?",
        linkVendita: shopUrl + "/index.php?controller=product&codice_articolo=",
        modale: [],
        slideAttiva: 0,
        richiestaLenti: async function (categoria) {
            this.urlLenti += "category="+categoria; 
            const response = await fetch(
                `${this.urlLenti}`
            );
            const data = await response.json();
            this.lenti = data;
            this.loading = false;
            // serve a togliere gli invii (\n\r) 
            this.lenti.forEach(element => {
                element.availablePowers = element.availablePowers.replace(/(?:\r\n|\r|\n)/g, '<br>');
            });
        },
        richiestaSoluzioni: async function (categoria) {
            this.urlSoluzioni += "category="+categoria; 
            const response = await fetch(
                `${this.urlSoluzioni}`
            );
            const data = await response.json();
            this.soluzioni = data;
            this.loading = false;
            this.soluzioni.forEach(element => {
                element.description = element.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
            });
        },
        popolaModale(item) {
            this.modale = item;
            this.slideAttiva = 0;
        },
        slideSuccessiva() {
            this.slideAttiva = (this.slideAttiva < this.modale.image.length - 1) ? this.slideAttiva + 1 : 0;
        },
        slidePrecedente() {
            this.slideAttiva = (this.slideAttiva > 0) ? this.slideAttiva - 1 : this.modale.image.length - 1;
        },
        mostraUrl(item) {
            if(item.urlEcommerce == true && item.code != item.name){
                return this.linkVendita + item.code; 
            }
        },
    }
}


export { prodotti }